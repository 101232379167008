import React from "react"
import {Link} from "gatsby"
import Navigation from "../components/navigationModule"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styles from '../components/contact.module.css'
import Footer from "../components/footer"
import get from 'lodash/get';
export default class RegisterForm extends React.Component {
    constructor(data) {
        super(data);

        this.state = this.initialState();

        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    initialState() {
        return {
            step: 1,
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: ''
        }
    }

    componentDidUpdate(prevProps) {
    }

    componentDidMount() {
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validate() {
        var invalidItems = []
        var data = this.state;
        Object
            .keys(data)
            .map(key => {
                var item = document.getElementsByName(key)[0];
                if (!item) {
                    return;
                }
                if (data[key] === '') {
                    item
                        .classList
                        .add("error");
                    invalidItems.push(key);
                } else {
                    if (key === "email") {
                        if (!this.validateEmail(data[key])) {
                            item
                                .classList
                                .add("error");
                            invalidItems.push(key);
                            return;
                        }
                    }
                    document
                        .getElementsByName(key)[0]
                        .classList
                        .remove("error");
                }

            });
        return invalidItems.length === 0
    }

    handleChange = e => {
        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                document
                    .getElementsByName(e.target.name)[0]
                    .classList
                    .add("error");
                return;
            }
        }
        document
            .getElementsByName(e.target.name)[0]
            .classList
            .remove("error");
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        if (!this.validate()) {
            return;
        }
        const encode = (data) => {

            return Object
                .keys(data)
                .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
                .join("&");
        }
        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: encode({
                "form-name": "contact",
                ...this.state
            })
        })
            .then(() => this.setState({
                step: this.state.step + 1
            }))
            .catch(error => alert(error));

        e.preventDefault();
    };

    render() {
        const {step} = this.state;
        const contactUs = get(this, 'props.data.allContentfulContactUs.nodes')[0]
        console.log(contactUs.contactAddressInfo)
        return (
            <>
                < SEO title="Contact us"/> <Navigation/>
                <section className="inner_content">
                    <div className={Styles.contactus_bannerimage}>
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">

                                    <div className={Styles.text}>
                                        <h2>contacts</h2>
                                        <p>
                                            get in touch
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container">
                        <div className="row slide-in-fwd-center contact_us">
                            <div className="col col-md-6 col-sm-12 col-12">
                                <h1>CONTACT INFO</h1>
                                <p>  {contactUs.sendUsMessageText.content[0].content[0].value}</p>
                                <div className="card">
                                    <div className={Styles.card_body}>
                                        <p><a target="blank" href={contactUs.googleMapLink} >{contactUs.location}</a></p>
                                        <p>{contactUs.pOBox}</p>
                                        <p><a href={`tel:+${contactUs.phoneNumberOne}`}>+{contactUs.phoneNumberOne}</a></p>
                                        <p><a href={`tel:+${contactUs.phoneNumberTwo}`}>+{contactUs.phoneNumberTwo}</a></p>
                                        <p><a href="mailto:info@tandemandstark.net">{contactUs.firstEmail}</a></p>
                                        <p><a href="mailto:projects@tandemandstark.net">{contactUs.secondEmail}</a></p>


                                    </div>
                                </div>
                            </div>

                            <div className="col col-md-6 col-sm-12 col-12">
                                <h1>SEND YOUR MESSAGE</h1>

                                <p>  {contactUs.contactInfo.content[0].content[0].value}</p>
                                <div className="">

                                    {
                                        step == 1 && <form
                                            name="contact"
                                            method="POST"
                                            data-netlify-honeypot="botfield"
                                            data-netlify="true">
                                            <div>
                                                <div className="d-none"><input type="text" name="bot-field"/></div>

                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12 col-12 input_name">
                                                            <input className="form-control firstname"
                                                                   placeholder="First Name*" type="text"
                                                                   name="firstName"
                                                                   onChange={this.handleChange}></input>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 col-12 ">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Last Name*"
                                                                name="lastName"
                                                                onChange={this.handleChange}/></div>
                                                    </div>

                                                </div>

                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Email*"
                                                                name="email"
                                                                onChange={this.handleChange}/>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Subject*"
                                                                name="subject"
                                                                onChange={this.handleChange}/>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                  <textarea
                                                      className="form-control"
                                                      placeholder="Message*"
                                                      name="message"
                                                      onChange={this.handleChange}></textarea>
                                                        </div>
                                                    </div>

                                                </div>


                                                <a className={Styles.contact_submitbutton} title="Send Message"
                                                   aria-label="Send Message" onClick={this.handleSubmit}
                                                   to="">Submit </a>
                                            </div>
                                        </form>

                                    }
                                    {
                                        step == 2 && <div>
                                            <h1>Message sent!</h1>
                                            <p>
                                                Thank you {this.state.firstname}, we will be in touch soon.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Footer/>
            </>
        )
    }
}


export const query = graphql`
    {
        allContentfulFooter {
            nodes {
                email
                firstPhoneNumber
                poBox
                secondPhoneNumber
                location
            }
        }
        allContentfulContactUs {
            nodes {
              location
              googleMapLink
              pOBox
              phoneNumberOne
              phoneNumberTwo
              firstEmail
              secondEmail
                contactInfo {
                    content {
                        content {
                            value
                        }
                    }
                }
                sendUsMessageText {
                    content {
                        content {
                            value
                        }
                    }
                }
            }
        }
      
    }
`;
